'use strict';
import Common from '../util/_common';

/**
 * @classname CartQuantity
 * @classdesc 絞り込み検索
 */
export default class CartQuantity {
	/**
	 * @constructor
	 * @param {jQuery} $e - .btn#filter
	 */
	constructor($e) {
		console.log(`CartQuantity.constructor`);
		this._$e = $e;
		$e.on('change', () => this.onChange());
	};

	/**
	 * clickのコールバック
	 *
	 * @memberof CartQuantity
	 * @return undefined
	 */
	onChange() {
		console.log(`CartQuantity.onChange`);
		const val = this._$e.val();
		$('#ctl00_ContentPlaceHolder1_tbCartAddProductCount').val(val);
	};
};
