'use strict';

/**
 * @classname SmoothScroll
 * @classdesc Topへ戻る
 */
export default class SmoothScroll {
	/**
	 * @constructor
	 * @param {jQuery} $e - body
	 */
	constructor($e) {
		console.log(`SmoothScroll.constructor`);
		this._$e = $e;
		$e.on('click', (e) => this.onClick(e));
	};

	/**
	 * clickのコールバック
	 *
	 * @memberof SmoothScroll
	 * @return undefined
	 */
	onClick(e) {
		console.log(`SmoothScroll.onClick`);
		e.preventDefault();
		const href = this._$e.attr('href');
		const target = $(href === '#' || href === '' ? 'html' : href);
		const position = target.offset().top;
		$('html, body').animate({scrollTop: position}, 500, 'swing');
	};
};
