'use strict';

// utility class
import ApplyClass from './util/_apply-class';
import Common from './util/_common';

import ScrollToTop from './view/ScrollToTop';
import SmoothScroll from './view/SmoothScroll';
import Slider from './view/Slider';
import Search from './view/Search';
import SearchClear from './view/SearchClear';
import Accordion from './view/Accordion';
import AccordionNews from './view/AccordionNews';
import CartQuantity from "./view/CartQuantity";
import CartVariation from "./view/CartVariation";
import ViewRecentProduct from "./view/ViewRecentProduct";
import ErrorBox from "./view/ErrorBox";
import PagerTotalZero from "./view/PagerTotalZero";

$(() => {
	Common.addHtmlClass();
	const $body = $('body');
	ApplyClass.apply(ScrollToTop, $body, [$body]);
	// smooth scroll
	setTimeout(() => {
		$('a[href^="#"]').each(function(idx, e) {
		const $e = $(e);
		ApplyClass.apply(SmoothScroll, $e, [$e]);
		});
	}, 1000);
	// エラーメッセージ
	$('.block--error-box').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(ErrorBox, $e, [$e]);
	});
	// swiper
	$('.swiper').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Slider, $e, [$e]);
	});
	// 絞り込み検索
	$('.btn#filter').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Search, $e, [$e]);
	});
	// 検索条件削除
	$('#search-clear').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(SearchClear, $e, [$e]);
	});
	// 絞り込み検索アコーディオン
	$('.btn--accordion').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Accordion, $e, [$e]);
	});
	// お知らせアコーディオン
	$('.anchor--news-list').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(AccordionNews, $e, [$e]);
	});
	// カート数量変更
	$('#cart-quantity').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CartQuantity, $e, [$e]);
	});
	// カートバリエーション
	$('.list--variation').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(CartVariation, $e, [$e]);
	});
	// 最新の放送商品
	$('#recent-products').each((idx, e)=> {
		const $e = $(e);
		ApplyClass.apply(ViewRecentProduct, $e, [$e]);
	});
	// 検索結果なし
	$('.pager--total').each((idx, e)=> {
		const $e = $(e);
		ApplyClass.apply(PagerTotalZero, $e, [$e]);
	});
});