'use strict';

/**
 * @classname ErrorBox
 * @classdesc エラーボックス
 */
export default class ErrorBox {
	/**
	 * @constructor
	 * @param {jQuery} $e - body
	 */
	constructor($e) {
		console.log(`ErrorBox.constructor`);
		const textLength = $e.text().length;
		if(textLength <= 12){
			$e.text('');
		}
	};
};
