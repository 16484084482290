'use strict';

/**
 * @classname Slider
 * @classdesc スライダー
 */
export default class Slider {
	/**
	 * @constructor
	 * @param {jQuery} $e - .swiper
	 */
	constructor($e) {
		console.log(`Slider.constructor`);
		this._$e = $e;
		const no = $e.attr('data-slider-type') || '1';
		const options = this.getOptions(no);
		console.log(this._$e[0]);
		this._Slider = new Swiper(this._$e[0], options);
		if(no === '3'){
			$('.anchor--image-switch').on('click', (e) => {
				this.switchImage(e);
			});
		}
	};

	/**
	 * オプションを取得する
	 *
	 * @memberof Slider
	 * @params {string} no - オプションNo
	 * @return {array} options - オプション
	 */
	getOptions(no) {
		console.log(`Slider.getOptions: ${no}`);
		let options = {};
		if(no === '1') {
			console.log('sliderType: 1');
			options = {
				speed: 400,
				loop: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				autoplay: {
					delay: 5000,
				},
				navigation: {
					nextEl: '.swiper-button-next-top',
					prevEl: '.swiper-button-prev-top',
				},
				pagination: {
					el: '.swiper-pagination-top',
					type: 'bullets',
				}
			};
		}else if(no === '2'){
			console.log('sliderType: 2');
			options = {
				slidesPerView: 4,
				spaceBetween: 19,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
				}
			};
		}else if(no === '3'){
			console.log('sliderType: 3');
			options = {
				loop: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			};
		}
		return options;
	};

	/**
	 * 画像を変更する
	 *
	 * @memberof Slider
	 * @params {object} e - クリックされた要素
	 */
	switchImage(e) {
		console.log(`Slider.switchImage`);
		const $e = $(e.currentTarget);
		const slideNo = parseInt($e.attr('data-image-no'));
		this._Slider.slideTo(slideNo, 500);
	}
};
