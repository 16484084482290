'use strict';

/**
 * @classname ScrollToTop
 * @classdesc Topへ戻る
 */
export default class ScrollToTop {
	/**
	 * @constructor
	 * @param {jQuery} $e - body
	 */
	constructor($e) {
		console.log(`ScrollToTop.constructor`);
		this._$e = $e;
		$e.append('<a class="btn btn--scroll-top" href="#top" style="display: none;"></a>');
		this._$btn = $('.btn--scroll-top');
		this._height = $(window).height() * .7;
		this._scroll = 0;
		$(window).on('scroll', () => this.onScroll());
	};

	/**
	 * scrollのコールバック
	 *
	 * @memberof ScrollToTop
	 * @return undefined
	 */
	onScroll() {
		this._scroll = $(window).scrollTop();
		if(this._scroll > this._height){
			this._$btn.fadeIn();
		}else{
			this._$btn.fadeOut();
		}
	};
};
