'use strict';

/**
 * @classname CartVariation
 * @classdesc 絞り込み検索
 */
export default class CartVariation {
	/**
	 * @constructor
	 * @param {jQuery} $e - .btn#filter
	 */
	constructor($e) {
		console.log(`CartVariation.constructor`);
		this._$e = $e;
		$('.btn--add-cart').addClass('disabled');
		$('.btn--add-cart.disabled').on('click', (e) => {
			e.preventDefault();
		})
		this._$btn = $e.find('.btn--variation');
		this._$btn.on('click', () => this.onClick());
	};

	/**
	 * clickのコールバック
	 *
	 * @memberof CartVariation
	 * @return undefined
	 */
	onClick() {
		console.log(`CartVariation.onClick`);
		$('.btn--add-cart').removeClass('disabled');
	};
};
