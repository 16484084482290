'use strict';

/**
 * @classname PagerTotalZero
 * @classdesc エラーボックス
 */
export default class PagerTotalZero {
	/**
	 * @constructor
	 * @param {jQuery} $e - body
	 */
	constructor($e) {
		console.log(`PagerTotalZero.constructor`);
		if($e.text().length < 8){
			$e.html('<p class="text text--search-result">全<strong>0</strong>件</p>');
		}
	};
};
