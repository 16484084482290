'use strict';
import Common from '../util/_common';

/**
 * @classname AccordionNews
 * @classdesc アコーディオン
 */
export default class AccordionNews {
	/**
	 * @constructor
	 * @param {jQuery} $e - .anchor--news-list
	 */
	constructor($e) {
		console.log(`AccordionNews.constructor`);
		this._$e = $e;
		this._$newsBody = $e.closest('.dl--news').find('.dd');
		$e.on('click', () => this.onClick());
		let anchor = location.hash;
		if(anchor.match(/^#/)){
			const $dl = $(anchor).closest('.dl');
			$dl.find('.dt .anchor--news-list').addClass('open');
			$dl.find('.dd').slideDown();
		}
	};

	/**
	 * clickのコールバック
	 *
	 * @memberof AccordionNews
	 * @return undefined
	 */
	onClick() {
		console.log(`AccordionNews.onClick`);
		if(this._$e.hasClass('open')){
			this._$e.removeClass('open').addClass('close');
			this._$newsBody.slideUp();
		}else{
			this._$e.removeClass('close').addClass('open');
			this._$newsBody.slideDown();
		}
	};
};
