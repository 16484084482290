'use strict';
import Common from '../util/_common';

/**
 * @classname Search
 * @classdesc 絞り込み検索
 */
export default class Search {
	/**
	 * @constructor
	 * @param {jQuery} $e - .btn#filter
	 */
	constructor($e) {
		console.log(`Search.constructor`);
		this._$e = $e;
		this._params = Common.getParams();
		console.log(this._params);
		$e.on('click', () => this.onClick());
	};

	/**
	 * clickのコールバック
	 *
	 * @memberof Search
	 * @return undefined
	 */
	onClick() {
		console.log(`Search.onClick`);
		const url = Common.getCurrentURL();
		const sort = $('.anchor--sort.active').attr('data-sort');
		let param = `?shop=0&dpcnt=30&img=2&sort=${sort}`;
		$('.search-item').each((idx, item) => {
			const $item = $(item);
			const element = $item.prop('tagName').toLowerCase();
			let name = $item.attr('data-name');
			let value;
			if(element === 'select') {
				value = $item.val();
			}else if(element === 'input'){
				if($item.attr('type') === 'radio') {
					value = $(`[data-name=${name}]:checked`).val();
				}else if($item.attr('type') === 'checkbox'){
					value = $(`[data-name=${name}]`).map((index, checkbox) => {
						const $checkBox = $(checkbox);
						if($checkBox.prop('checked')){
							return $checkBox.val();
						}
					}).get().join(',');
					value = encodeURIComponent(value);
					console.log(value);
				}else{
					value = $item.val();
				}
			}
			if($item.attr('data-name') === '_material' || $item.attr('data-name') === '_stone'){
				if(value !== ''){
					param = `${param}&${name}=${value}`;
				}
			}else{
				param = `${param}&${name}=${value}`;
			}
		});
		console.log(param);
		location.href = `${url}${param}&fpfl=&pno=1`;
	};
};
