'use strict';
import Common from '../util/_common';

/**
 * @classname ViewRecentProduct
 * @classdesc 最新の放送商品
 */
export default class ViewRecentProduct {
	/**
	 * @constructor
	 * @param {jQuery} $e - body
	 */
	constructor($e) {
		console.log(`ViewRecentProduct.constructor`);
		this._$e = $e;
		this._tapeProductNum = 0;
		this._productNum = 0;
		//Common.showLoading();
		$.ajax({
			type: 'get',
			url: '/Json/tape.json',
			data: {},
			cache: false,
			dataType: 'json',
			success: (tapeData)=> {
				Common.hideLoading();
				this.getTapesComplete(tapeData);
			},
			error: ()=> {
				Common.hideLoading();
			}
		})
	};

	/**
	 * テープ 一覧取得完了
	 *
	 * @memberof RecentProducts
	 * @param {json} tapeData - data|jqXHR
	 * @return {undefined}
	 */
	getTapesComplete(tapeData) {
		console.log(`ViewRecentProduct.getTapesComplete`);
		console.log('### TAPE DATA ###');
		console.log(tapeData);
		this._now = Common.getCurrentDateTime2();
		console.log(this._now);
		this._tapeData = tapeData;
		this._tapeArray = [];
		this._productArray = [];
		this._airDateArray = [];
		for (let i = 0; i < this._tapeData.length; i++) {
			if (this._tapeData[i].airDate < this._now) {
				if(!this._tapeArray.includes(this._tapeData[i].tapeId)){
					if (this._tapeProductNum < 18) {
						this._tapeArray.push(this._tapeData[i].tapeId);
						this._airDateArray.push(this._tapeData[i].airDateDisp);
						console.log(`BEFORE: ${this._tapeProductNum}`);
						this._tapeProductNum = this._tapeProductNum + parseInt(this._tapeData[i].productNum);
						console.log(`AFTER: ${this._tapeProductNum}`);
					} else {
						break;
					}
				}
			}
		}
		console.log('### TAPE PRODUCT NUM ###');
		console.log(this._tapeProductNum);
		console.log('### TAPE ###');
		console.log(this._tapeArray);
		//Common.showLoading();
		for (let j = 0; j < this._tapeArray.length; j++) {
			console.log(`start ${this._tapeArray[j]}.json`);
			$.ajax({
				type: 'get',
				url: `/Json/Products/${this._tapeArray[j]}.json`,
				data: {},
				cache: false,
				dataType: 'json',
				success: (productData) => {
					console.log('### PRODUCT DATA ###');
					console.log(productData);
					for(let k = 0; k < productData.length; k++){
						this._productArray.push(productData[k]);
						console.log('### PRODUCT ARRAY LENGTH ###');
						console.log(this._productArray.length);
						if(this._productArray.length === 18){
							Common.hideLoading();
							this.getProductsComplete();
						}
					}
				},
				error: () =>{
					Common.hideLoading();
				}
			});
		}
	};


	/**
	 * 商品一覧取得完了
	 *
	 * @memberof RecentProducts
	 * @return {undefined}
	 */
	getProductsComplete() {
		console.log(`RecentProducts.getProductsComplete`);
		Common.hideLoading();
		console.log('### PRODUCT ARRAY ###');
		console.log(this._productArray);
		for(let i = 0; i < this._tapeArray.length; i ++){
			for(let j = 0; j < this._productArray.length; j++){
				if(this._tapeArray[i] === this._productArray[j].tapeId){
					this.createHtml(this._productArray[j], this._airDateArray[i]);
				}
			}
		}
	};


	/**
	 * 商品カード作成
	 *
	 * @memberof RecentProducts
	 * @param {object} product - 商品データ
	 * @param {string} airDate - 放送日時
	 * @return {undefined}
	 */
	createHtml(product, airDate) {
		console.log(`RecentProducts.createHtml`);
		console.log(product.name);
		let html = '';
		if (this._productNum === 6) {
			let list = `<ul id="additionalProductWrap" class="list list--product-list" style="display:none;margin-bottom: 40px;"></ul>`;
			this._$e.after(list);
		}
		if (this._productNum <= 18) {
			html +=
`<li class="list__item">
	<p class="text mb5 fs18">${airDate}放送</p>
	<div class="card card--product card--3">
		<a class="anchor" href="Form/Product/ProductDetail.aspx?shop=0&pid=${product.pid}">
			<div class="card__header">
				<img class="image" src="${product.image}" alt="${product.name}" loading="lazy">
			</div>
			<div class="card__body">
				<p class="text text--product-name">${product.name}</p>`;
			if(product.price != 0){
				html += `
				<div className="block block--product-price">
					<p className="text text--product-price">¥${product.price.toLocaleString()}</p>
				</div>`;
			}
			html += `
			</div>
		</a>
	</div>
</li>`;
		}
		this._productNum++;
		console.log(this._productNum);
		if (this._productNum > 6) {
			$('#additionalProductWrap').append(html);
		} else {
			this._$e.append(html);
		}
		$('#show-more').css('display', 'flex');
		$('#show-more').on('click', ()=> {
			$('#additionalProductWrap').slideDown();
			$('#show-more').closest('.block--btn-box').fadeOut();
		});
	};
};
