'use strict';
import Common from '../util/_common';

/**
 * @classname Accordion
 * @classdesc アコーディオン
 */
export default class Accordion {
	/**
	 * @constructor
	 * @param {jQuery} $e - .btn--accordion
	 */
	constructor($e) {
		console.log(`Accordion.constructor`);
		this._$e = $e;
		this._$searchDetail = $('.block--search-detail-inner');
		$e.on('click', () => this.onClick());
		if(this._$e.hasClass('open')) this._$searchDetail.slideDown();
	};

	/**
	 * clickのコールバック
	 *
	 * @memberof Accordion
	 * @return undefined
	 */
	onClick() {
		console.log(`Accordion.onClick`);
		if(this._$e.hasClass('open')){
			this._$e.removeClass('open').addClass('close');
			this._$searchDetail.slideUp();
		}else{
			this._$e.removeClass('close').addClass('open');
			this._$searchDetail.slideDown();
		}
	};
};
