'use strict';
import Common from '../util/_common';

/**
 * @classname SearchClear
 * @classdesc 検索条件削除
 */
export default class SearchClear {
	/**
	 * @constructor
	 * @param {jQuery} $e - .btn#filter
	 */
	constructor($e) {
		console.log(`SearchClear.constructor`);
		this._$e = $e;
		this._params = Common.getParams();
		console.log(this._params);
		$e.on('click', () => this.onClick());
	};

	/**
	 * clickのコールバック
	 *
	 * @memberof SearchClear
	 * @return undefined
	 */
	onClick() {
		console.log(`SearchClear.onClick`);
		const url = Common.getCurrentURL();
		let param = `?shop=0&dpcnt=30&img=2&pno=1`;
		$('.SearchClear-item').each((idx, item) => {
			const $item = $(item);
			const element = $item.prop('tagName').toLowerCase();
			let name = $item.attr('data-name');
			let value;
			if(element === 'select') {
				value = $item.val();
			}else if(element === 'input'){
				if($item.attr('type') === 'radio') {
					value = $(`[data-name=${name}]:checked`).val();
				}else if($item.attr('type') === 'checkbox'){
					value = $(`[data-name=${name}]`).map((index, checkbox) => {
						const $checkBox = $(checkbox);
						if($checkBox.prop('checked')){
							return $checkBox.val();
						}
					}).get().join(',');
					value = encodeURIComponent(value);
					console.log(value);
				}else{
					value = $item.val();
				}
			}
			param = `${param}&${name}=${value}`;
		});
		console.log(param);
		location.href = `${url}${param}`;
	};
};
